import React from 'react';
import Countdown from 'react-countdown';
import { Box, Center, Text } from '@chakra-ui/react';

function EDSBS({ date }) {

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <Center align="center" bg="#ececec" w="100%" shadow="0 0 15px #000000" borderRadius="15px" overflow="hidden">
                    <Text color="white" fontSize="1rem" fontWeight="bold">
                        SATURDAY
                    </Text>
                </Center>
            );
        } else {
            return (
                <Center align="center" bg="#ececec" w="115%" shadow="0 0 15px #000000" borderRadius="15px" overflow="hidden">
                    <Text color="#000000" fontSize="1rem" fontWeight="bold" fontStyle="italic">
                        {days}:{hours}:{minutes}:{seconds}<br />Until Saturday
                    </Text>
                </Center>
            );
        }
    }

    return (
        <Countdown
            renderer={renderer}
            date={date}
            zeroPadTime={2}
        />
    );
}

export default EDSBS;

